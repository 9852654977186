export const NavData = [
  {
    id: "Home",
    path: "/",
    cName: "nav-text",
  },
  {
    id: "Inventory",
    path: "/inventory",
    cName: "nav-text",
  },
];
