export const ADDRESSES: { [key: number]: any } = {
  1: {
    KLIMATEES: "0xdcea6bdc97b964c9e3f5ce4d660797d8f7df9085",
    GENESIS_HOODIES: "0x1db04e0de39f14b78189df862910dd521567b9c0",
    BURN_CONTRACT: "0x8034EeE430ad78c4fcb2A4C1Ee5d4570fB63363e",
  },
  4: {
    KLIMATEES: "0x17923d7f7de514642531bcf567255ad2e09a7064",
    GENESIS_HOODIES: "0xfb6a45380d228d5f5b15f0893e7916a1d7d31473",
    BURN_CONTRACT: "0x2d41DeFdC0888a5D9a0356eA5D089A3D2Af06a51",
  },
};
